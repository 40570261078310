import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import './App.scss';

const App = () => {
  const [regNumber, setRegNumber] = useState('');
  const [fullVehicleName, setFullVehicleName] = useState('');
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    setRegNumber(cleanedValue);
    setErrorMessage('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && regNumber.trim() !== '' && !loading) {
      handleNextStep();
    }
  };

  const handleNextStep = async () => {
    if (step === 1 && regNumber.trim() !== '' && !loading) {
      try {
        setLoading(true);
  
        const response = await fetch(`https://api.motorcyclepartshub.com/RegistrationCheck/?reg=${regNumber}`);
        const data = await response.json();
  
        if (response.ok) {
          const fetchedVehicleInfo = {
            make: data.make,
            model: data.model,
            year: data.year
          };
  
          setVehicleInfo(fetchedVehicleInfo);
  
          const updatedFullVehicleName = `${fetchedVehicleInfo.make} ${fetchedVehicleInfo.model} ${fetchedVehicleInfo.year}`;
          setFullVehicleName(updatedFullVehicleName);
  
          setStep(step + 1);
        } else {
          setErrorMessage('Registration number does not exist');
        }
      } catch (error) {
        console.error('Error fetching vehicle information:', error);
        setErrorMessage('Registration number does not exist');
      } finally {
        setLoading(false);
      }
    } else if (step === 2) {
      setStep(step + 1);
    } else if (regNumber.trim() === '') {
      setErrorMessage('Please enter a registration number');
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const regParam = urlParams.get('reg');

    if (regParam) {
      const cleanedValue = regParam.replace(/[^a-zA-Z0-9]/g, '');
      setRegNumber(cleanedValue);
    }
  }, []);

  useEffect(() => {
    if (regNumber.trim() !== '' && window.location.search.includes('?reg=')) {
      handleNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regNumber]);

  return (
    <div className="app">
      {step === 1 && (
        <div className="regNumberForm">
          <div className="content">
            <label htmlFor="regNumber">Enter Registration Number:</label>
            <div className="input-box">
              <input
                className="registration-ui"
                autoComplete="off"
                id="regNumber"
                type="text"
                placeholder="R3G NO"
                maxLength="7"
                value={regNumber}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                required
              />
              <span className="unit">
                <span>UK</span>
              </span>
            </div>
          </div>
          <div className="actions">
            {errorMessage && (
              <p className="error-message">{errorMessage}</p>
            )}
            {!errorMessage && regNumber.trim() === '' && (
              <p className="error-message">Please enter a registration number</p>
            )}
            {!errorMessage && regNumber.trim() !== '' && (
              <input
                type="button"
                className="green"
                onClick={handleNextStep}
                value={loading ? 'Loading...' : 'Continue'}
                disabled={loading}
              />
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="confirmation">
          <div className="content">
            {vehicleInfo && vehicleInfo.make && vehicleInfo.make.toUpperCase() !== 'GENERIC' && (
              <img
                src={`https://motorcyclepartshub.com/wp-content/uploads/brand/${vehicleInfo.make.toLowerCase()}.svg`}
                alt={`${vehicleInfo.make}`}
                width="200"
              />
            )}
            <p>
              Is the motorcycle a{' '}
              <span className="vehicle">
                {vehicleInfo.make} {vehicleInfo.model} {vehicleInfo.year}
              </span>
              ?
            </p>
          </div>
          <div className="actions">
            <input className="red" type="button" onClick={handlePrevStep} value="No" />
            <input className="green" type="button" onClick={handleNextStep} value="Yes" />
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="qr">
          <div className="content">
            <img
              src="/img/jnr.jpeg"
              alt="JNR Motorcycles" height="100"
            />
            <br />
            {vehicleInfo && vehicleInfo.make && vehicleInfo.make.toUpperCase() !== 'GENERIC' && (
              <img
                src={`https://motorcyclepartshub.com/wp-content/uploads/brand/${vehicleInfo.make.toLowerCase()}.svg`}
                alt={`${vehicleInfo.make}`}
                width="200"
              />
            )}
            <h1>
              Thank you for purchasing your<br />
              <span className="vehicle">{fullVehicleName}</span><br />
              from JNR Motorcycles
            </h1>
            <p>Scan the QR code below or visit motorcyclepartshub.com to purchase parts for your new motorcycle</p>
            <QRCode value={`https://motorcyclepartshub.com/?reg=${regNumber}`} />
          </div>
          <div className="actions">
            <input className="red" type="button" onClick={handlePrevStep} value="Back" />
            <input className="blue" type="button" onClick={handlePrint} value="Print" />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
